<template>
  <div class="mt-8 relative flex justify-center">
    <img src="../assets/loading_logo.svg" alt="loading">
  </div>
</template>

<script>
  export default {
    name: "BiggerLoader"
  }
</script>

<style scoped>

</style>
